:root {
  --customRed: #cc2228;
}
.schwHeadContainer {
  /* border: solid; */
  display: flex;
  height: 10vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  margin-top: 5%;
}
.schwUnderline {
  border: 2px solid;
  color: var(--customColor);
  width: 50px;
}
.schwCardContainWrapper {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.schwCardContainer {
  /* border: solid green; */
  height: 50vh;
  width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  margin: 30px;
  box-shadow: 0px -10px 70px -50px, 0px -10px 70px -50px black;
}
.schwCardImg {
  /* border: solid purple; */
  width: 100%;
  height: 70%;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.schwCardImg > img {
  width: 90%;
  height: 90%;
}
.schwCardTxt {
  /* border: solid blue; */
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* background-color: var(--customRed); */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.schwCardHead {
  /* border: solid pink; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.schwCardHead > h2 {
  font-size: 110%;
  text-align: center;
  color: var(--customColor);
}
.schwCardTxt > button > a {
  border: none;
  background-color: var(--customRed);
  width: 100%;
  height: 90%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  font-size: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}
.schwCardTxt > button {
  border: none;
  /* border: rebeccapurple solid; */
  background-color: var(--customRed);
  width: 100%;
  height: 30%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  font-size: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.schwWhats {
  font-size: 20px;
  color: #00a884;
}
