
.membersMainContainer {
  /* border: solid; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-image: url("./../../../Images//HomeBg/memberBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.headingContainer {
  /* border: solid; */
  height: 130px;
}
.firstMemberHeading {
  /* border: solid; */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.firstMemberHeading > h4 {
  font-size: 30px;
  color: var(--customColor);
}
.secondMemberHeading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.secondMemberHeading > h4 {
  font-size: 40px;
  color: black;
}
.secondMemberHeading > h4 > span {
  color: var(--customColor);
}
.memberSlideContainer {
  /* border: solid; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.memberSlideContent {
  /* border: solid red; */
  width: 90%;
  height: 95%;
}
.memberSlide {
  /* border:blue solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 10px;
}
.memberSlide > img {
  width: 90%;
  height: 100%;
  margin-left: 15px;
  /* filter: grayscale(1); */
}
.memberSlide > h3 {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--customColor);
}
.memberSlide > pre {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .membersMainContainer {
    /* border: solid; */
    height: 100%;
    width: 100%;
  }
  .secondMemberHeading > h4 {
    font-size: 30px;
    color: black;
  }
  .memberSlideContainer {
    /* border: solid red; */
    max-width: 300px;
    height: 100%;
  }
  .memberSlideContent {
    /* border: solid; */
    height: 100%;
    width: 100%;
  }
  .memberSlide {
    /* border: blue solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 30px; */
    height: 50vh;
    width: 100%;
    padding: 20px;
  }
  .memberSlide > h3 {
    /* border: solid; */
    font-size: 150%;
  }
  .memberSlide > img {
    /* border: solid red; */
    height: 100%;
    width: 100%;
    margin-left: 0px;
  }
  .memberSlide > pre {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 150%;
  }
}
@media only screen and (min-width: 990px) and (max-width: 1200px) {
  .memberSlideContent {
    /* border: solid red; */
    width: 100%;
    height: 95%;
  }
  .memberSlide > img {

    width: 100%;
    height: 100%;
    margin-left: 0;
    /* filter: grayscale(1); */
  }
}
