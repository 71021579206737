.blogMainContainer {
  /* border: solid red; */
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
}
.blogMainDisplay1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px;
  gap: 30px;
}
.blogMainContent {
  /* border: solid blue; */
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px;
}
.blogMainDisplayContent {
  /* border: solid blue; */
  width: 100%;
  height: fit-content;
  display: none;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px;
}

.blogCardContainer {
  /* border: solid green; */
  width: 350px;
  height: 450px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 0 10px grey;
  border-radius: 10px;
}
.blogCardImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.BlogCardBut {
  /* border: solid brown; */
  width: 100px;
  height: 40px;
}
.blogCardTitle > h2 {
  color: var(--customColor);
  font-size: 20px;
}
.BlogCardBut > button {
  width: 100%;
  height: 100%;
  padding: 5px;
  font-size: 15px;
  background-color: var(--customColor);
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.BlogCardBut > button > a {
  text-decoration: none;
  color: white;
}
.blogMainReadMore {
  /* border: solid green; */
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blogMainReadMore > button {
  width: 200px;
  height: 100%;
  background-color: var(--customColor);
  color: white;
  border: none;
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
}
.blogCardPara > p {
  text-align: justify;
}
/* --------------------pagination------------------- */

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pageButton {
  border: 2px solid var(--customColor);
  padding: 10px 15px;
  margin: 0 5px;
  background-color: var(--customColor);
  cursor: pointer;
  font-size: 18px;
  color: white;
}

.pageButton.active {
  border: 2px solid var(--customColor);
  background-color: white;
  color: var(--customColor);
}

.pageButton:hover {
  border: 2px solid var(--customColor);
  background-color: white;
  color: var(--customColor);
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .blogMainContainer {
    /* border: solid red; */
    padding: 10px;
  }
  .blogCardContainer {
    /* border: solid green; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }
  .blogMainContent {
    /* border: solid blue; */
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    padding: 20px;
  }
  .blogMainDisplayContent {
    /* border: solid blue; */
    width: 100%;
    height: fit-content;
    display: none;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    padding: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .blogMainContainer {
    /* border: solid red; */
    padding: 0px;
  }
  .blogCardContainer {
    /* border: solid green; */
    min-width: 300px;
    gap: 20px;
    padding: 0px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .blogMainDisplay1 {
    /* border: solid blue; */
    justify-content: center;
    align-items: center;
  }

  .blogMainDisplay2 {
    /* border: solid blue; */
    justify-content: center;
    align-items: center;
  }
}
