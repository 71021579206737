.pediAndMainCureBenefits {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  width: 90%;
  padding: 20px;
}
.pediAndMainCureBenefits > h1 {
  font-size: 30px;
  color: var(--customColor);
  text-align: center;
}
.pediAndMainCureBenefits > h2 {
  color: var(--customColor);
  width: 100%;
  text-align: center;
  font-size: 30px;
}
.pediAndMainCureBenefits > h3 {
  color: var(--customColor);
  width: 100%;
  text-align: start;
  font-size: 25px;
}
.pediAndMainCureBenefits > p {
  width: 100%;
  font-size: 20px;
  text-align: justify;
}
.pediAndMainCureBenefits > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.pediAndMainCureBenefits > li > h6 {
  color: var(--customColor);
  font-size: 20px;
}

.pediAndMainCureBenefits > li {
  /* border: solid; */
  width: 100%;
  font-size: 20px;
  text-align: justify;
  list-style: none;
}
.pediAndMainCureBenefits > li > span {
  /* border: solid; */
  color: var(--customColor);
}
.pediAndMainCureBenefits > li > p > a {
  text-decoration: none;
  color: var(--customColor);
}
.pediAndMainCureBenefits > li > a {
  text-decoration: none;
  color: var(--customColor);
}
.pediAndMainCureBenefits > li > ul > li {
  /* border: solid; */
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.pediAndMainCureBenefits > li > ul > li > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--customColor);
  text-align: start;
  /* font-weight: bold; */
}
.pediAndMainCureBenefits > li > ul > li > span > img {
  width: 20px;
  height: 20px;
}
.pediAndMainCureBenefits > li > ul > li > span > p {
  color: black;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .pediAndMainCureBenefits > h2 {
    color: var(--customColor);
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  .pediAndMainCureBenefits > h3 {
    color: var(--customColor);
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
}
