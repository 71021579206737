
.salonContainer {
  /* border: solid; */
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  position: relative;
  overflow: hidden;
  background-color: white;

}
.salonContainer > img {
  /* border: solid; */
  height: 100%;
  width: 100%;
  position: absolute;
  right: -350px;
  z-index: -1;
}
.salonContent1 {
  /* border: solid; */
  width: 500px;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.salonContent1 > p {
  font-size: 30px;
  text-align: center;
  color: var(--customColor);
}
.salonUnderline {
  border: 2px solid;
  color: var(--customColor);
  width: 100px;
}
.salonContent2 {
  /* border: solid; */
  width: 500px;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.salonItem {
  border: solid;
  color: var(--customColor);
  border-radius: 50%;
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.salonItem > p {
  color: black;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.salonContent3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 140px;
  cursor: pointer;
}
.salonContent3 > a {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
}
.salonContent3 > a > p {
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: lighter;
  text-decoration: underline;
  cursor: pointer;
  color: var(--customColor);
  font-weight: bold;
}
.salonIcon {
  color: var(--customColor);
}
@media only screen and (max-width: 600px) {
  .salonContainer {
    /* border: solid red; */
    height: 400px;
    width: 100%;
    gap: 40px;
    margin-top: 30px;
  }
  .salonItem {
    /* border: solid green; */
    height: 100px;
    width: 100px;
  }
  .salonContent1 {
    /* border: solid blue; */
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .salonContent1 > p {
    font-size: 170%;
    text-align: center;
    color: var(--customColor);
    /* border: solid; */
    width: 90%;
  }
  .salonContent2 {
    /* border: solid; */
    width: 100%;
    height: 120px;
  }
  .salonContent3 {
    /* border: solid; */
    width: 180px;
  }
  .salonItem > p {
    color: black;
    font-size: 90%;
    font-weight: bold;
    text-align: center;
  }
  .salonContent3 > p {
    font-size: 100%;
  }
}
@media screen and (max-width: 990px) and (min-width: 600px) {
  .salonContainer {
    /* border: solid red; */
    height: 400px;
    width: 100%;
    gap: 40px;
    margin-top: 30px;
  }
  .salonItem {
    /* border: solid green; */
    height: 100px;
    width: 100px;
  }
  .salonContent1 {
    /* border: solid blue; */
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .salonContent1 > p {
    font-size: 170%;
    text-align: center;
    color: var(--customColor);
    /* border: solid; */
    width: 90%;
  }
  .salonContent2 {
    /* border: solid; */
    width: 70%;
    height: 120px;
  }
  .salonContent3 {
    /* border: solid; */
    width: 180px;
  }
  .salonItem > p {
    color: black;
    font-size: 90%;
    font-weight: bold;
    text-align: center;
  }
  .salonContent3 > p {
    font-size: 100%;
  }
}
@media screen  and (min-width: 990px) and (max-width: 1200px) {
  .salonContainer {
    /* border: solid green; */
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .salonContent1 {
    /* border: solid; */
    width: 500px;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .salonContent1 > p {
    font-size: 30px;
    text-align: center;
    color: var(--customColor);
  }
  .salonUnderline {
    border: 2px solid;
    color: var(--customColor);
    width: 100px;
  }
  .salonContent2 {
    /* border: solid; */
    width: 500px;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .salonItem {
    border: solid;
    color: var(--customColor);
    border-radius: 50%;
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .salonItem > p {
    color: black;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
  .salonContent3 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 140px;
    cursor: pointer;
  }
  .salonContent3 > p {
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: lighter;
    text-decoration: underline;
    cursor: pointer;
  }
}
