:root {
  --customRed: #cc2228;
  --customTransRed: #cc2228bb;
}

.helpNearYouContainer {
  /* border: solid; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  /* margin-top: 40px; */
  padding: 20px;
  background-color: white;
}
.helpNearYouHeadContent {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}
.helpNearYouHeadContent > h5 {
  font-size: 30px;
  text-align: center;
  color: var(--customColor);
}
.helpNearYouUnderline {
  border: 2px solid;
  width: 80px;
  color: var(--customColor);
}
.helpNearYouParaContainer > p {
  font-size: 20px;
  text-align: center;
}
.helpCardContainer > h3 {
  color: white;
  border: solid;
}
.helpNearYouCardContainer {
  /* border: solid red; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.helpCardContainer {
  /* border: solid green; */
  width: 30%;
  height: 300px;
  display: flex;
  margin: 20px;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: 300ms ease-in-out;
  overflow: hidden;
}
.helpCardContainer > p {
  color: white;
}
.helpPhoneIcon {
  color: white;
}
.helpCardContainer:hover .helpImg {
  transform: scale(1.03);
}
.helpCardContainer > img {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 200ms ease-in-out;
}
.helpCity > h3 > a {
  color: white;
  text-decoration: none;
}
.helpCity > p {
  color: white;
}
.helpCover {
  /* border: solid; */
  height: 50px;
  width: 100%;
  background-color: var(--customTransRed);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: -100px;
  transition: 200ms ease-in-out;
}
.helpCardContainer:hover .helpCover {
  bottom: 0;
}
.helpCity {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  gap: 20px;
  /* gap: 10px; */
  font-weight: bold;
}
.respHelpCardContainer {
  /* border: solid; */
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.respHelpNearYouCardContainer {
  /* border: solid red; */
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.respImage {
  /* border: solid red; */
  height: 90%;
  width: 100%;
}
.respHelpCardCover {
  /* border: solid green; */
  height: 35%;
  width: 100%;
  background-color: var(--customRed);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.respHelpCardSlider {
  /* border: solid yellow; */
  width: 80%;
  display: none;
}
.respHelpCardCover > h3 > a {
  color: white;
  text-decoration: none;
}
.respHelpCardCover > p {
  color: white;
  font-size: 100%;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .helpNearYouContainer {
    /* border: solid; */
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    margin-top: 50px;
  }

  .helpNearYouHeadContent > h5 {
    font-size: 150%;
  }
  .helpNearYouParaContainer {
    text-align: center;
  }
  .helpNearYouParaContainer > p {
    font-size: 20px;
  }
  .helpNearYouCardContainer {
    display: none;
  }
  .respHelpNearYouCardContainer {
    /* border: solid red; */
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .helpCardContainer {
    display: none;
  }
  .respHelpCardSlider {
    display: flex;
  }
  .respHelpCardContainer {
    display: flex;
  }
  .helpCover {
    /* border: solid; */
    height: 50px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
  }
  .helpCity > h3 {
    font-size: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .helpCardContainer {
    /* border: solid green; */
    min-width: 300px;
    height: 250px;
  }
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
  .helpCardContainer {
    /* border: solid green; */
    width: 400px;
    height: 300px;
  }
}
